import {v4 as uuidv4} from "uuid";
import jwt from "jsonwebtoken";
import axios from "axios";

class TeliaeClient {
    constructor() {
        this.apiPath = "";

        // Generate a JWT token
        this.token = jwt.sign(
            { jti: uuidv4() },
            process.env.REACT_APP_LINKBOX_APP_API_TOKEN_KEY
        );

        // Initialize Axios instance
        this.axiosInstance = axios.create({
            baseURL: `${process.env.REACT_APP_LINKBOX_APP_API_HOST}api/teliae`,
            headers: {
                "x-access-token": this.token,
            },
        });
    }

    api(path) {
        this.apiPath = path;
        return this;
    }

    async get() {
        try {
            return await this.axiosInstance.get(`/get`, {
                params: {
                    api: this.apiPath,
                    _t: new Date().getTime() // Unique timestamp to prevent caching
                }
            });
        } catch (error) {
            console.error("TeliaeClient GET error:", error);
            throw error;
        }
    }
}

export default new TeliaeClient();
