import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {getAddressTypes} from "./addressTypesSlice";
import {getOutboundOrderTypes} from "./outboundOrderTypeSlice";
import {getCustomerOrdersShippingMethods} from "./shippingMethodSlice";
import sgMailConfig from "../../apis/sgMailConfig";
import {formatDate} from "../../../@utils";
import {languageCodeOnly} from "../../../i18n";
import sgMail from "../../apis/sgMail";
import {getCountries} from "./countrySlice";
import {createGetUserAddressesByIdsThunk} from "../thunks/createGetUserAddressesByIdsThunk";

export const getCheckout = createAsyncThunk(
    'checkout/fetch',
    async (idCart, {rejectWithValue, getState, dispatch}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const activeWarehouse = getState().warehouse.active;

            const checkout = await limApi.get(`/clients/accounts/${idClientAccount}/warehouse/${activeWarehouse.idWarehouse}/checkout/${idCart}`).then(res => res.data.checkout);

            await dispatch(getAddressTypes());
            if (activeWarehouse?.warehouseType === 'BOX') {
                await dispatch(getOutboundOrderTypes());
            }
            if (activeWarehouse?.warehouseType === 'WAREHOUSE') {
                await dispatch(getCustomerOrdersShippingMethods());
            }

            const shippingMethod = getState().shippingMethod.availableList.find(shippingMethod => shippingMethod.idShippingMethod === checkout.idShippingMethod);

            return {
                ...checkout,
                shippingMethod: shippingMethod || ""
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

// Function to process orders for personalizations
const processCustomerOrderConfirmationEmailPersonalizations = async (orders, idClient, idClientAccount, state) => {
    const personalizations = [];
    for (const idOrder of orders) {
        const order = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/customerorders/${idOrder}`)
            .then(response => response.data.customerOrder)
            .catch(e => void console.log(e));

        if (order) {
            try {
                const personalization = {
                    to: [
                        {
                            email: state.user.email
                        }
                    ],
                    dynamic_template_data: {
                        user: {
                            firstName: state.user.firstName,
                            lastName: state.user.lastName
                        },
                        order: {
                            orderNumber: order.orderNumber,
                            requestedDeliveryDate: formatDate(order.deliveries[0].requestedDeliveryDate),
                            inboundName: order.deliveries[0].inboundName,
                            deliveryAddress1: order.deliveries[0].deliveryAddress1,
                            deliveryAddress2: order.deliveries[0].deliveryAddress2,
                            deliveryAddressZipCode: order.deliveries[0].deliveryAddressZipCode,
                            deliveryAddressCity: order.deliveries[0].deliveryAddressCity,
                            deliveryAddressCountryISO2: state.country.list.find((country) => country.isO2 === order.deliveries[0].deliveryAddressCountryISO2).countryNameEN,
                            outboundName: order.deliveries[0].outboundName,
                            outboundAddress1: state.warehouse.active.address1,
                            outboundAddress2: state.warehouse.active.address2,
                            outboundZipCode: state.warehouse.active.zipCode,
                            outboundCity: state.warehouse.active.city,
                            outboundCountry: state.warehouse.active.country,
                            products: order.deliveries[0].products
                        },
                        appUrl: sgMailConfig.appUrl
                    }
                }

                personalizations.push(personalization);
            } catch (error) {
                // Log error or handle it as needed for failed personalization build
                console.error(error);
            }
        }
    }
    return personalizations;
};

const sendCustomerOrderConfirmationEmail = async (personalizations = [], langCode) => {
    const msg = {
        from: sgMailConfig.from,
        personalizations,
        template_id: sgMailConfig.templatesId.order.confirmation[languageCodeOnly(langCode)]
    };

    await sgMail.send(msg).catch(err => {
        console.error(err)
    });
}

export const completeCheckout = createAsyncThunk(
    'checkout/complete',
    async (idCart, {rejectWithValue, getState, dispatch}) => {
        try {
            let state = getState();
            const idClient = selectUserIdClient(state);
            const idClientAccount = selectUserIdClientAccount(state);
            const activeWarehouse = state.warehouse.active;

            const response = await limApi.post(`/clients/accounts/${idClientAccount}/warehouse/${activeWarehouse.idWarehouse}/checkout/${idCart}/complete?type=${activeWarehouse.warehouseType}`).then(response => response.data);

            if (activeWarehouse.warehouseType === "WAREHOUSE") {
                if (state.country.list.length === 0) {
                    await dispatch(getCountries()).catch(e => void console.log(e));
                    state = getState();
                }

                const personalizations = response.orders ?
                    await processCustomerOrderConfirmationEmailPersonalizations(response.orders, idClient, idClientAccount, state) :
                    await processCustomerOrderConfirmationEmailPersonalizations([response.idOrder], idClient, idClientAccount, state);

                if (personalizations && personalizations.length > 0) {
                    await sendCustomerOrderConfirmationEmail(personalizations, state.user.cultureLanguageCode);
                }
            }

            await dispatch(resetCheckout());

            return response;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const draftCheckout = createAsyncThunk(
    'checkout/draft',
    async (idCart, {rejectWithValue, getState, dispatch}) => {
        try {
            let state = getState();
            const idClientAccount = selectUserIdClientAccount(state);
            const activeWarehouse = state.warehouse.active;

            const response = await limApi.post(`/clients/accounts/${idClientAccount}/warehouse/${activeWarehouse.idWarehouse}/checkout/${idCart}/draft`).then(response => response.data);

            await dispatch(resetCheckout());

            return response;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const updateCheckout = createAsyncThunk(
    'checkout/update',
    async ({idCart, data}, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());
            const activeWarehouse = getState().warehouse.active;

            const response = await limApi.put(`/clients/accounts/${idClientAccount}/warehouse/${activeWarehouse.idWarehouse}/checkout/${idCart}`, data);

            return response.data.checkout;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const checkoutGetAddresses = createGetUserAddressesByIdsThunk('checkout/getAddresses');

const initialState = {
    loading: undefined,
    idShippingMethod: null,
    shippingMethod: '',
    orderType: null,
    deliveryDate: null,
    shipToMultiple: null,
    addresses: [],
    selectedAddresses: [],
    idBoxUser: null,
    boxUserFirstName: null,
    boxUserLastName: null,
    boxUserEmail: null,
    boxUserMobile: null,
    boxUserPhone: null,
    addressComment: null,
    orderComment: null,
    orderReference: null,
    poNumber: null
};

const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        setCheckoutData: (state, action) => {
            const {shippingMethod} = action.payload;
            const {isProvisionAtDock} = shippingMethod || {};
            // do this here because of access to prev state
            if (isProvisionAtDock !== undefined) {
                const {isProvisionAtDock: prevIsProvisionAtDock} = current(state).shippingMethod || {};
                if (isProvisionAtDock !== prevIsProvisionAtDock) {
                    state.selectedAddresses = [];
                }
            }

            Object.assign(state, action.payload)
        },
        resetCheckout: () => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCheckout.pending, state => {
                if (!state.idCart) {
                    state.loading = true;
                }
            })
            .addCase(getCheckout.fulfilled, (state, action) => {
                state.loading = undefined;
                Object.assign(state, action.payload)
            })
            .addCase(getCheckout.rejected, state => {
                state.loading = undefined
            })
            .addCase(updateCheckout.fulfilled, (state, action) => {
                Object.assign(state, action.payload);
            })
            .addCase(checkoutGetAddresses.fulfilled, (state, action) => {
                state.selectedAddresses = action.payload;
            })
    }
});

export const {setCheckoutData, resetCheckout} = checkoutSlice.actions;

export default checkoutSlice.reducer;