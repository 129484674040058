export const customerOrderStatuses = {
    0: 'Annulée',
    1: 'Prise en compte',
    10: 'En Cours',
    30: 'Préparation',
    40: 'Clôturée',
    60: 'Expédiée',
    63: 'Transit',
    64: 'Mis en livraison',
    66: 'Traitée',
    70: 'Livrée'
}

export const supplierOrderStatuses = {
    0: 'Annulée',
    1: 'Attente de réception',
    20: 'Réception en Cours',
    30: 'Reçu partiellement',
    50: 'Réceptionnée',
    60: 'Expiré'
}

export const SUPPLIER_ORDER_TYPE_SUPPLIER = 'Supplier';

export const SUPPLIER_ORDER_TYPE_RETURN = 'Return';

export const supplierOrderTypes = {
    [SUPPLIER_ORDER_TYPE_SUPPLIER]: 'Supplier',
    [SUPPLIER_ORDER_TYPE_RETURN]: 'Return'
}

/**
 * Retrieves tracking information from order deliveries.
 *
 * @param {Array} orderDeliveries - Array of order deliveries.
 * @returns {Array} Array of order shipments objects.
 */
export const getCustomerOrderShipments = (orderDeliveries = []) => {
    const trackingInfo = [];

    for (const delivery of orderDeliveries) {
        if (delivery.shipments) {
            for (const shipment of delivery.shipments) {
                // if (shipment.carrier && shipment.trackingNumber) {
                    trackingInfo.push(shipment);
                // }
            }
        }
    }

    return trackingInfo;
};

export const createFlatShipmentProducts = (customerOrder) => {
    const flatProducts = [];

    // Iterate over each delivery
    customerOrder.deliveries.forEach(delivery => {
        // Iterate over each shipment in the delivery
        delivery.shipments.forEach(shipment => {
            // Iterate over each product in the shipment
            shipment.products.forEach(product => {
                flatProducts.push(product);
            });
        });
    });

    return flatProducts;
};