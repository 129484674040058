import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {isEqual, some} from "lodash";

export const getClientAccountWarehouses = createAsyncThunk(
    'clientAccountWarehouse/fetchAll',
    async ({idClient, idClientAccount}, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/warehouses?pageSize=10000`);
            return response.data.clientAccountWarehouses;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postClientAccountWarehouse = createAsyncThunk(
    'clientAccountWarehouse/create',
    async ({idClient, idClientAccount, data}, {rejectWithValue}) => {
        try {
            const response = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/warehouses`, data);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteClientAccountWarehouse = createAsyncThunk(
    'clientAccountWarehouse/delete',
    async ({idClient, idClientAccount, idWarehouse}, {rejectWithValue}) => {
        try {
            const response = await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/warehouses/${idWarehouse}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const clientAccountWarehouseSlice = createSlice({
    name: 'clientAccountWarehouse',
    initialState: {
        list: []
    },
    reducers: {
        resetClientAccountWarehouse: state => {
            state.list = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getClientAccountWarehouses.fulfilled, (state, action) => {
                state.list = action.payload;
            })
    }
});

export const updateClientAccountWarehouses = (idClient, idClientAccount, clientAccountWarehouses) => async (dispatch, getState) => {
    const oldRefs = getState().clientAccountWarehouse.list.map(cw => ({
        accountWMS: cw.accountWMS,
        idWarehouse: cw.idWarehouse,
        idClientAccountWarehouse: cw.idClientAccountWarehouse,
        wmsProductFamilyCode: cw.wmsProductFamilyCode
    }));

    const equal = isEqual(oldRefs, clientAccountWarehouses)

    if (!equal) {
        // DELETE/REMOVE ref
        const deleteRefs = oldRefs.filter(old => {
            const exist = some(clientAccountWarehouses, old);
            if (!exist) {
                return old;
            }
            return null;
        });

        for (const ref of deleteRefs) {
            await dispatch(deleteClientAccountWarehouse({idClient, idClientAccount, idWarehouse: ref.idClientAccountWarehouse}))
                .unwrap()
                .catch(e => {
                    throw Error(e.message);
                });
        }
        // DELETE/REMOVE ref

        // ADD ref
        const addRefs = clientAccountWarehouses.filter(ref => {
            const exist = some(oldRefs, ref);
            if (!exist) {
                return ref;
            }
            return null;
        });

        for (const ref of addRefs) {
            await dispatch(postClientAccountWarehouse({idClient, idClientAccount, data: ref}))
                .unwrap()
                .catch(e => {
                    throw Error(e.message);
                });
        }
        // ADD ref

        await dispatch(getClientAccountWarehouses({idClient, idClientAccount}))
    }
}

export const {resetClientAccountWarehouse} = clientAccountWarehouseSlice.actions;

export default clientAccountWarehouseSlice.reducer;