import boxStockSlice from "./slices/boxStockSlice";
import userSlice from "./slices/userSlice";
import userAddressBookSlice from "./slices/userAddressBookSlice";
import boxPictureSlice from "./slices/boxPictureSlice";
import warehouseSlice from "./slices/warehouseSlice";
import supportSlice from "./slices/supportSlice";
import inboundOrdersSlice from "./slices/inboundOrdersSlice";
import reportsSlice from "./slices/reportsSlice";
import outboundOrdersSlice from "./slices/outboundOrdersSlice";
import paymentMethodSlice from "./slices/paymentMethodSlice";
import paymentTermSlice from "./slices/paymentTermSlice";
import outboundOrderTypeSlice from "./slices/outboundOrderTypeSlice";
import activitySlice from "./slices/activitySlice";
import cultureLanguageSlice from "./slices/cultureLanguageSlice";
import stockRegularizationReasonSlice from "./slices/stockRegularizationReasonSlice";
import quotaSlice from "./slices/quotaSlice";
import adminCustomizerSlice from "./slices/adminCustomizerSlice";
import stockRegularizationDocumentProductSlice from "./slices/stockRegularizationDocumentProductSlice";
import stockRegularizationDocumentSlice from "./slices/stockRegularizationDocumentSlice";
import applicationUsersRolesSlice from "./slices/applicationUsersRolesSlice";
import addressBookAddressSlice from "./slices/addressBookAddressSlice";
import addressBookGroupSlice from "./slices/addressBookGroupSlice";
import addressBookSlice from "./slices/addressBookSlice";
import customerOrdersSlice from "./slices/customerOrdersSlice";
import presetColorSlice from "./slices/presetColorSlice";
import imageSlice from "./slices/imageSlice";
import applicationUsersSlice from "./slices/applicationUsersSlice";
import clientAccountUserSlice from "./slices/clientAccountUserSlice";
import clientAccountUserRoleSlice from "./slices/clientAccountUserRoleSlice";
import addressTypesSlice from "./slices/addressTypesSlice";
import shippingMethodSlice from "./slices/shippingMethodSlice";
import accountSlice from "./slices/accountSlice";
import clientAccountWarehouseSlice from "./slices/clientAccountWarehouseSlice";
import productLabelSlice from "./slices/productLabelSlice";
import productTagSlice from "./slices/productTagSlice";
import productChildSlice from "./slices/productChildSlice";
import hierarchyModelSlice from "./slices/hierarchyModelSlice";
import hierarchySlice from "./slices/hierarchySlice";
import quotaHierarchySlice from "./slices/quotaHierarchySlice";
import customReportsSlice from "./slices/customReportsSlice";
import userQuotaSlice from "./slices/userQuotaSlice";
import clientUserSlice from "./slices/clientUserSlice";
import quotaPersonalSlice from "./slices/quotaPersonalSlice";
import quotaUserGroupSlice from "./slices/quotaUserGroupSlice";
import boxUserSlice from "./slices/boxUserSlice";
import boxSlice from "./slices/boxSlice";
import providerSlice from "./slices/providerSlice";
import shippingMethodGroupSlice from "./slices/shippingMethodGroupSlice";
import documentSlice from "./slices/documentSlice";
import providerSiteContactSlice from "./slices/providerSiteContactSlice";
import providerSiteSlice from "./slices/providerSiteSlice";
import countrySlice from "./slices/countrySlice";
import categorySlice from "./slices/categorySlice";
import dashboardSlice from "./slices/dashboardSlice";
import productCategorySlice from "./slices/productCategorySlice";
import productDocumentSlice from "./slices/productDocumentSlice";
import productImageSlice from "./slices/productImageSlice";
import productSlice from "./slices/productSlice";
import ecommerceSlice from "./slices/ecommerceSlice";
import layoutSlice from "./slices/layoutSlice";
import masqueradeSlice from "./slices/masqueradeSlice";
import customerOrderDocumentsSlice from "./slices/customerOrderDocumentsSlice";
import clientAccountFeaturesSlice from "./slices/clientAccountFeaturesSlice";
import applicationRolesSlice from "./slices/applicationRolesSlice";
import rolePermissionsSlice from "./slices/rolePermissionsSlice";
import catalogGroupSlice from "./slices/catalogGroupSlice";
import groupUserSlice from "./slices/groupUserSlice";
import groupsSlice from "./slices/groupsSlice";
import clientSlice from "./slices/clientSlice";
import catalogSlice from "./slices/catalogSlice";
import cartSlice from "./slices/cartSlice";
import applicationSlice from "./slices/applicationSlice";
import clientAccountProductAttributesSlice from "./slices/clientAccountProductAttributesSlice";
import productAttributesSlice from "./slices/productAttributesSlice";
import checkoutSlice from "./slices/checkoutSlice";
import checkoutDocumentsSlice from "./slices/checkoutDocumentsSlice";
import globalSlice from "./slices/globalSlice";
import eventsSlice from "./slices/eventsSlice";
import eventTagSlice from "./slices/eventTagSlice";
import eventStockReservationsSlice from "./slices/eventStockReservationsSlice";
import kitSlice from "./slices/kitSlice";
import kitComponentSlice from "./slices/kitComponentSlice";
import supplierSlice from "./slices/supplierSlice";
import supplierOrderSlice from "./slices/supplierOrderSlice";
import draftOrdersSlice from "./slices/draftOrderSlice";

export default {
    account: accountSlice,
    activity: activitySlice,
    addressBook: addressBookSlice,
    addressBookAddress: addressBookAddressSlice,
    addressBookGroup: addressBookGroupSlice,
    addressTypes: addressTypesSlice,
    adminCustomizer: adminCustomizerSlice,
    application: applicationSlice,
    applicationRoles: applicationRolesSlice,
    applicationUsers: applicationUsersSlice,
    applicationUsersRoles: applicationUsersRolesSlice,
    box: boxSlice,
    boxPicture: boxPictureSlice,
    boxStock: boxStockSlice,
    boxUser: boxUserSlice,
    cart: cartSlice,
    catalogs: catalogSlice,
    catalogGroup: catalogGroupSlice,
    category: categorySlice,
    checkoutDocuments: checkoutDocumentsSlice,
    checkout: checkoutSlice,
    clientAccountFeatures: clientAccountFeaturesSlice,
    clientAccountProductAttributes: clientAccountProductAttributesSlice,
    clientAccountUser: clientAccountUserSlice,
    clientAccountUserRole: clientAccountUserRoleSlice,
    clientAccountWarehouse: clientAccountWarehouseSlice,
    clientUser: clientUserSlice,
    clients: clientSlice,
    country: countrySlice,
    cultureLanguage: cultureLanguageSlice,
    customerOrderDocuments: customerOrderDocumentsSlice,
    customerOrders: customerOrdersSlice,
    customReports: customReportsSlice,
    dashboard: dashboardSlice,
    hierarchyModel: hierarchyModelSlice,
    hierarchy: hierarchySlice,
    document: documentSlice,
    draftOrder: draftOrdersSlice,
    ecommerce: ecommerceSlice,
    events: eventsSlice,
    eventStockReservations: eventStockReservationsSlice,
    eventTag: eventTagSlice,
    global: globalSlice,
    groups: groupsSlice,
    groupUser: groupUserSlice,
    image: imageSlice,
    inboundOrders: inboundOrdersSlice,
    kit: kitSlice,
    kitComponent: kitComponentSlice,
    layout: layoutSlice,
    masquerade: masqueradeSlice,
    outboundOrders: outboundOrdersSlice,
    outboundOrderType: outboundOrderTypeSlice,
    paymentMethod: paymentMethodSlice,
    paymentTerm: paymentTermSlice,
    presetColor: presetColorSlice,
    productAttributes: productAttributesSlice,
    productCategory: productCategorySlice,
    productChildSlice: productChildSlice,
    productDocument: productDocumentSlice,
    productImage: productImageSlice,
    productLabel: productLabelSlice,
    productTag: productTagSlice,
    products: productSlice,
    providers: providerSlice,
    providerSiteContact: providerSiteContactSlice,
    providerSite: providerSiteSlice,
    reports: reportsSlice,
    rolePermissions: rolePermissionsSlice,
    quotaHierarchy: quotaHierarchySlice,
    quotaPersonal: quotaPersonalSlice,
    quota: quotaSlice,
    quotaUserGroup: quotaUserGroupSlice,
    shippingMethodGroup: shippingMethodGroupSlice,
    shippingMethod: shippingMethodSlice,
    stockRegularizationDocument: stockRegularizationDocumentSlice,
    stockRegularizationDocumentProduct: stockRegularizationDocumentProductSlice,
    stockRegularizationReason: stockRegularizationReasonSlice,
    supplierOrder: supplierOrderSlice,
    supplier: supplierSlice,
    support: supportSlice,
    user: userSlice,
    userAddressBook: userAddressBookSlice,
    userQuota: userQuotaSlice,
    warehouse: warehouseSlice
};
