import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {isEmpty} from "lodash";
import limApi, {RECORD_STATUS_NEW} from "../../apis/limApi";
import {attachProductToCategory} from "./productCategorySlice";
import {updateProductLabels} from "./productLabelSlice";
import {updateAccountProductTags} from "./productTagSlice";
import {getProductImages, postProductImage, updateProductImages} from "./productImageSlice";
import {getProductDocuments, postProductDocument, updateProductDocuments} from "./productDocumentSlice";
import {postProductChild, updateProductChild} from "./productChildSlice";
import {removeNonPrintableCharacters} from "../../../@utils";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {productTypes} from "../../views/app/catalog-admin/products/form-model/formInitialValues";
import {postProductAttribute, updateProductAttributes} from "./productAttributesSlice";

export const getProducts = createAsyncThunk(
    "product/fetchAll",
    async ({queryParams = "", type}, {rejectWithValue, getState}) => {
        const idClient = selectUserIdClient(getState());
        const idClientAccount = selectUserIdClientAccount(getState());

        
        if (type) {
            const queryParamsObj = Object.fromEntries(
                new URLSearchParams(queryParams)
            );

            const typeFilter = {
                filters: [{
                    field: "type",
                    value: type,
                    operator: "eq"
                }]
            }

            if ("filterGroups" in queryParamsObj) {
                // attach typeFilter to existing filterGroups
                queryParamsObj.filterGroups = queryParamsObj.filterGroups.slice(0, -1) + "," + JSON.stringify(typeFilter) + "]";
            } else {
                // create filterGroups if not exists
                queryParamsObj.filterGroups = JSON.stringify([typeFilter]);
            }

            queryParams = new URLSearchParams(queryParamsObj).toString();
        }

        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProduct = createAsyncThunk(
    "product/fetchOne",
    async (idProduct, {rejectWithValue, getState}) => {
        const idClient = selectUserIdClient(getState());
        const idClientAccount = selectUserIdClientAccount(getState());

        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products/${idProduct}`);
            return response.data.product;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const createProduct = createAsyncThunk(
    "product/create",
    async ({idClient, idClientAccount, values}, {rejectWithValue, dispatch}) => {
        try {
            const product = await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/products`, {
                ...values,
                productDescription: removeNonPrintableCharacters(values.productDescription),
                priceSell: values.type === productTypes['simple'].value ? values.priceSell : 0,
                pricePurshase: values.type === productTypes['simple'].value ? values.pricePurshase : 0
            }).then(res => res.data.product);

            if (!isEmpty(values.productCategories)) {
                await dispatch(attachProductToCategory(idClient, idClientAccount, product.idProduct, values.productCategories));
            }

            if (!isEmpty(values.productLabels)) {
                await dispatch(updateProductLabels(product.idProduct, values.productLabels))
            }

            if (values.productTags.length > 0) {
                await dispatch(updateAccountProductTags(idClientAccount, product.idProduct, values.productTags));
            }

            if (values.images.length > 0) {
                for (const image of values.images) {
                    await dispatch(postProductImage({idProduct: product.idProduct, imageBase64: image.base64.split(',')[1], values: image}));
                }
            }

            if (values.documents.length > 0) {
                for (const document of values.documents) {
                    await dispatch(postProductDocument({idProduct: product.idProduct, documentBase64: document.base64.split(',')[1], values: document}));
                }
            }

            if ((values.type === productTypes['bundle'].value || values.type === productTypes['configurable'].value) && values.children.length > 0) {
                for (const child of values.children) {
                    const attributeOptions = child.attributeOptions ? Object.entries(child.attributeOptions).map(option => ({
                        idAttribute: option[0],
                        idAttributeOption: option[1].idAttributeOption,
                        recordStatus: RECORD_STATUS_NEW
                    })) : [];

                    await dispatch(postProductChild({
                        idProduct: product.idProduct,
                        data: {
                            idProduct: product.idProduct,
                            idClientAccount,
                            quantity: child.quantity,
                            idChild: child.idChild,
                            attributeOptions
                        }
                    }));
                }
            }

            if (values.type === productTypes['configurable'].value && values.productAttributes.length > 0) {
                for (const idAttribute of values.productAttributes) {
                    await dispatch(postProductAttribute({
                        idProduct: product.idProduct,
                        data: {
                            idProduct: product.idProduct,
                            idAttribute
                        }
                    }))
                }
            }

            return product;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const updateProduct = createAsyncThunk(
    "product/update",
    async ({idClient, idClientAccount, idProduct, values}, {rejectWithValue, dispatch, getState}) => {
        try {
            const prevProduct = getState().products.product;
            if (prevProduct.type !== values.type) {
                throw Error('Changing Type is not allowed!');
            }

            const response = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/products/${idProduct}`,{
                idProduct: idProduct,
                idClientAccount: idClientAccount,
                // sku: values.sku,
                // skuClient: values.skuClient,
                skuSupplier: values.skuSupplier,
                priceSell: values.type === productTypes['simple'].value ? values.priceSell : 0,
                channel: values.channel,
                productGroup: values.productGroup,
                supplier: values.supplier,
                productShortName: values.productShortName,
                productName: values.productName,
                productDescription: removeNonPrintableCharacters(values.productDescription),
                brand: values.brand,
                productFamily: values.productFamily,
                productSubFamily: values.productSubFamily,
                enabled: values.enabled,
                expectingReturn: values.expectingReturn,
                prepareDays: +values.prepareDays,
                displayPosition: values.displayPosition,
                stockThresholdAlert: parseInt(values.stockThresholdAlert),
                stockBuffer: parseInt(values.stockBuffer),
                barcodeAvailable: values.barcodeAvailable,
                barcode: values.barcode,
                hsCode: values.hsCode,
                logisticProfile: values.logisticProfile
                // activateLots: values.activateLots
            });

            if (!isEmpty(values.productCategories)) {
                await dispatch(attachProductToCategory(idClient, idClientAccount, idProduct, values.productCategories));
            }

            if (!isEmpty(values.productLabels)) {
                await dispatch(updateProductLabels(idProduct, values.productLabels))
            }

            if (values.productTags.length > 0) {
                await dispatch(updateAccountProductTags(idClientAccount, idProduct, values.productTags));
            }

            if ((values.type === productTypes['bundle'].value || values.type === productTypes['configurable'].value)) {
                await dispatch(updateProductChild(idClientAccount, idProduct, values.children));
            }

            await dispatch(updateProductImages(idProduct, values.images));
            await dispatch(updateProductDocuments(idProduct, values.documents));

            if (values.type === productTypes['configurable'].value && values.productAttributes.length > 0) {
                await dispatch(updateProductAttributes(idProduct, values.productAttributes));
            }

            await dispatch(getProductImages(idProduct));
            await dispatch(getProductDocuments(idProduct));
            await dispatch(getProduct(idProduct));

            return response.data.product;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteProduct = createAsyncThunk(
    "product/delete",
    async ({idClient, idClientAccount, idProduct}, {rejectWithValue, dispatch}) => {
        try {
            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/products/${idProduct}`);
            await dispatch(getProducts({idClient, idClientAccount}));
            return true;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const searchProducts = createAsyncThunk(
    "product/search",
    async (query, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const jsonParams = {
                currentPage: 1,
                pageSize: 30,
                filterGroups: JSON.stringify([
                    {
                        filters: [
                            {
                                field: "productName",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            },
                            {
                                field: "skuclient",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            }
                        ]
                    },
                    {
                        filters: [{
                            field: "type",
                            value: productTypes["configurable"].value,
                            operator: "neq"
                        }]
                    }
                ])
            }

            const queryParams = new URLSearchParams(jsonParams).toString();
            // Using this endpoint instead products/search because of product type filter
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products?${queryParams}`);
            return response.data.items;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const bulkImportProducts = createAsyncThunk(
    "product/bulkImport",
    async ({data}, {rejectWithValue}) => {
        try {
            return await limApi.post('/products/import', data).then(response => response.data);
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const productSlice = createSlice({
    name: "product",
    initialState: {
        list: [],
        listLoading: undefined,
        product: undefined,
        loading: undefined,
        searchProducts: [],
        searchProductsLoading: undefined,
    },
    reducers: {
        resetProducts: state => {
            state.list = [];
        },
        resetProduct: state => {
            state.product = undefined;
        },
        resetSearchProducts: state => {
            state.searchProducts = [];
        },
        setSearchProductOffset: (state, action) => {
            state.offsetSearchProducts = action.payload;
        },
        setSearchEndOffset: (state, action) => {
            state.offsetSearchEnd = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProducts.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getProducts.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getProduct.pending, state => {
                if (!state.product) {
                    state.loading = true;
                }
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.loading = undefined;
                state.product = action.payload;
            })
            .addCase(getProduct.rejected, state => {
                state.loading = undefined;
            })
            .addCase(searchProducts.pending, state => {
                state.searchProductsLoading = true;
            })
            .addCase(searchProducts.fulfilled, (state, action) => {
                state.searchProductsLoading = undefined;
                state.searchProducts = action.payload;
            })
            .addCase(searchProducts.rejected, state => {
                state.searchProductsLoading = undefined;
            })
    }
});

export const {resetProducts, resetProduct, resetSearchProducts, setSearchProductOffset, setSearchEndOffset} = productSlice.actions;

export default productSlice.reducer;