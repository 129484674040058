import {createSlice} from "@reduxjs/toolkit";
import localStorageService from "../../services/localStorageService";
import {resetActiveWarehouse, resetWarehouses, setActiveWarehouse} from "./warehouseSlice";
import {emptyCart} from "./cartSlice";
import {getUserClientLogoId, initUserData, selectUserEventAccount, selectUserIdClientAccount} from "./userSlice";
import {getCustomReports} from "./customReportsSlice";
import {getEcommerceCatalogs} from "./ecommerceSlice";
import {gaEvents} from "../../services/googleAnalytics";
import {defineAbilitiesForAccount, getAbilitiesForAccount} from "../../casl/Ability";
import {SYSTEM_ADMIN_ROLE} from "../../apis/limApi";
import {resetDashboard} from "./dashboardSlice";
import {setMasqueradeSession} from "./masqueradeSlice";
import history from "../../../@history";
import {batch} from "react-redux";
import {getActiveEvent, resetActiveEvent} from "./eventsSlice";

const adminCustomizerSlice = createSlice({
    name: 'adminCustomizer',
    initialState: {
        loading: undefined,
        settings: undefined,
        open: false
    },
    reducers: {
        adminCustomizerSetSettings: (state, action) => {
            state.settings = action.payload;
        },
        getAdminCustomizerSettingsInternal: state => {
            state.settings = localStorageService.getItem('adminCustomizerSettings') || undefined;
        },
        resetAdminCustomizerSettings : state => {
            localStorageService.removeItem('adminCustomizerSettings');
            state.settings = undefined
        },
        setAdminCustomizerOpen: (state, action) => {
            state.open = action.payload;
        },
        setAdminCustomizerSettingsLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const {
    adminCustomizerSetSettings,
    getAdminCustomizerSettingsInternal,
    setAdminCustomizerOpen,
    setAdminCustomizerSettingsLoading,
    resetAdminCustomizerSettings
} = adminCustomizerSlice.actions;

export const getAdminCustomizerSettings = () => (dispatch, getState) => {
    const user = getState().user;
    const systemAdministrator = user.accountPermissions.find(permission => permission.idClientAccount === SYSTEM_ADMIN_ROLE);

    if (!systemAdministrator && user.accountPermissions.length <= 1 && !getState().global.appGlobalError) {
        localStorageService.removeItem('adminCustomizerSettings')
    }
    dispatch(getAdminCustomizerSettingsInternal());
}

export const setAdminCustomizerSettings = (data) => async (dispatch, getState) => {
    dispatch(setAdminCustomizerSettingsLoading(true));

    const clientObj = getState().clients.clientsList.find(client => client.idClient === data.idClient)
    data.presetColor = clientObj.presetColor;
    const user = getState().user

    localStorageService.setItem('adminCustomizerSettings', data);
    dispatch(adminCustomizerSetSettings(data));

    defineAbilitiesForAccount(user.accountPermissions, data.idClientAccount);

    if (selectUserEventAccount(getState())) {
        await dispatch(getActiveEvent());
    } else if (getState().events.active) {
        await dispatch(resetActiveEvent());
    }

    await dispatch(setActiveWarehouse(data.idWarehouse));
    await dispatch(resetDashboard());
    await dispatch(emptyCart());
    await dispatch(getUserClientLogoId());

    const idClientAccount = selectUserIdClientAccount(getState());
    const accountAbilities = getAbilitiesForAccount(user.accountPermissions, idClientAccount, 'View');

    if (accountAbilities?.subject.includes('CatalogCatalogCatalogCategory')) {
        await dispatch(getEcommerceCatalogs());
    }

    await dispatch(getCustomReports(data.idClientAccount));

    gaEvents.setUserData(user, getState().clients, {settings: data});

    dispatch(setAdminCustomizerSettingsLoading(undefined));
}

export const cancelAdminCustomizerSettings = () => async (dispatch) => {
    history.replace("/");
    batch(() => {
        dispatch(resetAdminCustomizerSettings());
        dispatch(resetActiveWarehouse());
        dispatch(resetWarehouses());
        dispatch(resetActiveEvent());
        dispatch(resetDashboard());
        dispatch(emptyCart());
        dispatch(initUserData());
    })
}

export default adminCustomizerSlice.reducer;