import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import limApi from "../../apis/limApi";
import {selectUserIdClientAccount} from "./userSlice";

export const getDashboardCustomerOrders = createAsyncThunk(
    "dashboard/fetchCustomerOrders",
    async (_, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            if (!idClientAccount) {
                return rejectWithValue({ message: "idClientAccount is missing" });
            }

            const response = await limApi.get(`/dashboard/${idClientAccount}/customerorders`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getDashboardBoxOrders = createAsyncThunk(
    "dashboard/fetchBoxOrders",
    async (idBox, {rejectWithValue}) => {
        try {
            const response = await limApi.get(`/dashboard/boxorders/${idBox}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getDashboardProducts = createAsyncThunk(
    "dashboard/fetchProducts",
    async (_, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            if (!idClientAccount) {
                return rejectWithValue({ message: "idClientAccount is missing" });
            }

            const response = await limApi.get(`/dashboard/${idClientAccount}/products`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const getDashboardCatalogsItems = createAsyncThunk(
    "dashboard/fetchCatalogsItems",
    async (idWarehouse, {rejectWithValue, getState}) => {
        try {
            const idClientAccount = selectUserIdClientAccount(getState());

            if (!idClientAccount) {
                return rejectWithValue({ message: "idClientAccount is missing" });
            }

            const response = await limApi.get(`/dashboard/${idClientAccount}/catalogs/${idWarehouse}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const initialState = {
    catalogsItems: {},
    catalogsItemsLoading: undefined,
    products: [],
    productsLoading: undefined,
    customerOrders: {},
    customerOrdersLoading: undefined,
    boxOrders: {},
    boxOrdersLoading: undefined
}

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        resetDashboard: () => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDashboardCustomerOrders.pending, state => {
                if (!state.customerOrders.total) {
                    state.customerOrdersLoading = true;
                }
            })
            .addCase(getDashboardCustomerOrders.fulfilled, (state, action) => {
                state.customerOrdersLoading = initialState.customerOrdersLoading;
                state.customerOrders = action.payload;
            })
            .addCase(getDashboardCustomerOrders.rejected, state => {
                state.customerOrdersLoading = initialState.customerOrdersLoading;
            })
            .addCase(getDashboardBoxOrders.pending, state => {
                if (!state.boxOrders.inboundOrders || !state.boxOrders.outboundOrders) {
                    state.boxOrdersLoading = true;
                }
            })
            .addCase(getDashboardBoxOrders.fulfilled, (state, action) => {
                state.boxOrdersLoading = initialState.boxOrdersLoading;
                state.boxOrders = action.payload;
            })
            .addCase(getDashboardBoxOrders.rejected, state => {
                state.boxOrdersLoading = initialState.boxOrdersLoading;
            })
            .addCase(getDashboardProducts.pending, state => {
                if (!state.products.length) {
                    state.productsLoading = true;
                }
            })
            .addCase(getDashboardProducts.fulfilled, (state, action) => {
                state.productsLoading = initialState.productsLoading;
                state.products = action.payload;
            })
            .addCase(getDashboardProducts.rejected, state => {
                state.productsLoading = initialState.productsLoading;
            })
            .addCase(getDashboardCatalogsItems.pending, state => {
                if (!state.catalogsItems.itemsTotal) {
                    state.catalogsItemsLoading = true;
                }
            })
            .addCase(getDashboardCatalogsItems.fulfilled, (state, action) => {
                state.catalogsItemsLoading = initialState.catalogsItemsLoading;
                state.catalogsItems = action.payload;
            })
            .addCase(getDashboardCatalogsItems.rejected, state => {
                state.catalogsItemsLoading = initialState.catalogsItemsLoading;
            })
    }
});

export const {resetDashboard} = dashboardSlice.actions;

export default dashboardSlice.reducer;