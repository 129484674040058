import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import limApi from "../../apis/limApi";
import {getDocument} from "./documentSlice";
import {createGetUserAddressesByIdsThunk} from "../thunks/createGetUserAddressesByIdsThunk";
import {getCustomerOrdersShippingMethods} from "./shippingMethodSlice";
import {productTypes} from "../../views/app/catalog-admin/products/form-model/formInitialValues";

export const getDraftOrders = createAsyncThunk(
    "draftOrder/fetchAll",
    async ({idWarehouse = "",  queryParams = ""}, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/draftorders?${queryParams}${idWarehouse ? `&idWarehouse=${idWarehouse}` : ""}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const getDraftOrderHelperData = (order) => async (dispatch) => {
    if (order.documents.length > 0) {
        for (const document of order.documents) {
            await dispatch(getDocument(document.idDocument));
        }
    }

    if (order.addresses.length > 0) {
        await dispatch(draftOrderGetAddresses({addresses: order.addresses}));
    }

    await dispatch(getCustomerOrdersShippingMethods())
        .unwrap()
        .then(response => {
            const shippingMethod = response.find(shippingMethod => shippingMethod.idShippingMethod === order.idShippingMethod)
            dispatch(setShippingMethod(shippingMethod));
        });
}

export const getDraftOrder = createAsyncThunk(
    "draftOrder/fetchOne",
    async (idDraft, {rejectWithValue, getState, dispatch}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const order =  await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/draftorders/${idDraft}`).then(response => response.data.draftOrder);

            await dispatch(getDraftOrderHelperData(order));

            return order;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postDraftOrder = createAsyncThunk(
    "draftOrder/create",
    async ({data}, {rejectWithValue, getState, dispatch}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            return await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/draftorders`, data).then(response => response.data.draftOrder);
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putDraftOrder = createAsyncThunk(
    "draftOrder/update",
    async ({idDraft, data}, {rejectWithValue, getState, dispatch}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const order = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/draftorders/${idDraft}`, data).then(response => response.data.draftOrder);

            await dispatch(getDraftOrderHelperData(order));

            return order;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteDraftOrder = createAsyncThunk(
    "draftOrder/delete",
    async (idDraft, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/draftorders/${idDraft}`);
            return true
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const draftOrdersSearchProduct = createAsyncThunk(
    "draftOrder/searchProduct",
    async ({query}, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const jsonParams = {
                currentPage: 1,
                pageSize: 30,
                filterGroups: JSON.stringify([
                    {
                        filters: [
                            {
                                field: "productName",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            },
                            {
                                field: "productShortName",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            },
                            {
                                field: "sku",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            },
                            {
                                field: "skuClient",
                                value: `%${query.toLowerCase()}%`,
                                operator: "like"
                            }
                        ]
                    },
                    {
                        filters: [{
                            field: "type",
                            value: productTypes["simple"].value,
                            operator: "eq"
                        }]
                    }
                ])
            }

            const queryParams = new URLSearchParams(jsonParams).toString();
            // Using this endpoint instead products/search because of product type filter
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products?${queryParams}`);

            return response.data.items;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const draftOrderGetAddresses =  createGetUserAddressesByIdsThunk('draftOrder/getAddresses');

const initialState = {
    list: [],
    listLoading: undefined,
    order: undefined,
    selectedAddresses: undefined,
    shippingMethod: undefined,
    loading: undefined,
    productsSuggestions: undefined,
    suggestionsLoading: undefined,
    errors: [],
}

const draftOrderSlice = createSlice({
    name: "draftOrder",
    initialState,
    reducers: {
        resetDraftOrders: state => {
            state.list = initialState.list;
        },
        resetDraftOrder: state => {
            state.order = initialState.order;
            state.selectedAddresses = initialState.selectedAddresses;
            state.shippingMethod = initialState.shippingMethod;
            state.errors = initialState.errors;
        },
        setShippingMethod: (state, action) => {
            state.shippingMethod = action.payload;
        },
        resetDraftOrderProductSuggestions: state => {
            state.productsSuggestions = initialState.productsSuggestions;
        },
        setDraftOrderErrors: (state, action) => {
            const {idProduct, diff} = action.payload;
            const errorIndex = state.errors.findIndex((err) => err.idProduct === idProduct);

            if (diff < 0 && errorIndex === -1) {
                state.errors.push(action.payload);
            } else if (diff >= 0) {
                state.errors = state.errors.filter((err) => err.idProduct !== idProduct);
            }
        },
        resetDraftOrderErrors: state => {
            state.errors = initialState.errors;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDraftOrders.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = true;
                }
            })
            .addCase(getDraftOrders.fulfilled, (state, action) => {
                state.list = action.payload;
                state.listLoading = undefined;
            })
            .addCase(getDraftOrders.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getDraftOrder.pending, state => {
                state.loading = true;
            })
            .addCase(getDraftOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.loading = undefined;
            })
            .addCase(getDraftOrder.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putDraftOrder.fulfilled, (state, action) => {
                state.order = action.payload;
            })
            .addCase(draftOrderGetAddresses.fulfilled, (state, action) => {
                state.selectedAddresses = action.payload;
            })
            .addCase(draftOrdersSearchProduct.pending, state => {
                state.suggestionsLoading = true;
            })
            .addCase(draftOrdersSearchProduct.fulfilled, (state, action) => {
                state.productsSuggestions = action.payload;
                state.suggestionsLoading = undefined;
            })
            .addCase(draftOrdersSearchProduct.rejected, state => {
                state.suggestionsLoading = undefined;
            })
    }
});

export const {
    resetDraftOrders,
    resetDraftOrder,
    setShippingMethod,
    resetDraftOrderProductSuggestions,
    setDraftOrderErrors,
    resetDraftOrderErrors
} = draftOrderSlice.actions;

export default  draftOrderSlice.reducer;