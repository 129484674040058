import {createListenerMiddleware, isAnyOf, isRejectedWithValue} from "@reduxjs/toolkit";
import {setAppGlobalError, setAppNotification} from "../slices/globalSlice";
import {getDraftOrder} from "../slices/draftOrderSlice";
import history from "@history";

const globalErrorListenerMiddleware = createListenerMiddleware();

globalErrorListenerMiddleware.startListening({
    predicate: (action, currentState) => {
        const user = currentState.user;

        if (currentState.global.appGlobalError) return false;

        if (action.type === 'user/getUserInfo/rejected') {
            return true;
        }

        if (action.type === 'warehouse/fetchAll/rejected' && user.initialLoading) {
            return true;
        }
        return false;
    },
    effect: (action, listenerApi) => {
        listenerApi.dispatch(setAppGlobalError({
            code: null,
            message: [action.error.message || '', action.error.stack || '', action.payload?.message || '', action.payload?.response?.request?.responseURL || ''].filter(error => error).join("<br />")
        }));
        listenerApi.cancelActiveListeners();
    },
});

globalErrorListenerMiddleware.startListening({
    predicate: (action) => action.type === "global/setAppNotification" && action.payload,
    effect: async (_, {dispatch, delay, cancelActiveListeners}) => {
        // Cancel any in-progress instances of this listener
        cancelActiveListeners();

        await delay(100);
        dispatch(setAppNotification(undefined));
    }
});

globalErrorListenerMiddleware.startListening({
    predicate: (action) => {
        return isRejectedWithValue(action) &&
            isAnyOf(getDraftOrder.rejected)(action) &&
            action.payload?.response?.status === 404
    },
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        history.replace("/session/404");
    }
});

export default globalErrorListenerMiddleware;