import {createAsyncThunk} from "@reduxjs/toolkit";
import {selectUserIdClientAccount} from "../slices/userSlice";
import limApi from "../../apis/limApi";

export const createGetUserAddressesByIdsThunk = (typePrefix) =>
    createAsyncThunk(
        typePrefix,
        async ({ addresses = [], idClientAccount = null }, { getState, rejectWithValue }) => {
            try {
                if (!idClientAccount) {
                    idClientAccount = selectUserIdClientAccount(getState());
                }

                const jsonParams = {
                    currentPage: 1,
                    filterGroups: [
                        {
                            filters: [
                                {
                                    field: "enabled",
                                    value: "1",
                                    operator: "eq",
                                },
                            ],
                        },
                        {
                            filters: [
                                {
                                    field: "idAddressBookAddress",
                                    value: addresses.map((idAddressBookAddress) => `'${idAddressBookAddress}'`).join(","),
                                    operator: "in",
                                },
                            ],
                        },
                    ],
                };

                if (addresses.length > 0) {
                    const response = await limApi.put(
                        `/userinfo/addressbook${idClientAccount ? `?idClientAccount=${idClientAccount}` : ""}`,
                        jsonParams
                    );
                    return response.data.items;
                }
                return [];
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );